import { useEffect, useRef, useState } from "react";
import Stack from "@mui/material/Stack";
import {
  AutomateProgressStatusDocument,
  GetUrlDocument,
  UserRollbackProcessDocument,
} from "../../../generated";
import Button from "@mui/material/Button";
import { useLazyQuery } from "@apollo/react-hooks";
import RollbackAccountModal from "./RollbackAccountModal";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface AccountState {
  automateProgress: boolean;
  progressStatus: string;
  createdDate: string;
  rollbackOngoing: boolean;
  accountDeleted: boolean;
}

interface RollbackAccountProps {
  organisation: string;
  arn: string;
  accessnickname: string;
  externalId: string;
  defaultregion: string;
  accountStates: Record<string, AccountState>;
  updateAccountState:(arn: string, newState: Partial<AccountState>) => void;
  // setProgressStatus: any;
  // setAutomateProgress: any;
  // setAccountDeleted: any;
  // rollbackOngoing: boolean;
  // setRollbackOngoing: (index: boolean) => void;
}

export default function RollbackAccount({
  organisation,
  arn,
  accessnickname,
  externalId,
  defaultregion,
  accountStates,
  updateAccountState,
  // setProgressStatus,
  // setAutomateProgress,
  // setAccountDeleted,
  // rollbackOngoing,
  // setRollbackOngoing,
}: RollbackAccountProps) {
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [rollbackStatus, setRollbackStatus] = useState(false);
  const [displayQuestions, setDisplayQuestions] = useState(false);
  const [templateUrl, setTemplateUrl] = useState("");
  let accountid = arn.split(":")[4];

  const formikRef = useRef<any>(null); //Used to clear the formik form if handleClose is called

  const handleOpen = () => {
    setOpen(true);
    setDisable(false);
    setRollbackStatus(false);
  };

  const handleClose = () => {
    setOpen(false);
    setDisable(false);
    setRollbackStatus(false);
    setDisplayQuestions(false);
    //Clears the form if the modal is closed
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  };

  // Call Automate Progress Status Query Once Upgrade Process Statemachine has been triggered
  const [automateProgressStatus] = useLazyQuery(
    AutomateProgressStatusDocument,
    {
      onCompleted: (ProgressStatusData) => {
        // setProgressStatus(
        //   ProgressStatusData?.automateProgressStatus?.status || ""
        // );
        updateAccountState(arn, {
          automateProgress: true,
          progressStatus: ProgressStatusData?.automateProgressStatus?.status || "",
          rollbackOngoing: true,
          accountDeleted: true,
        });
        setRollbackStatus(false);
        // setAutomateProgress(true);
        // setAccountDeleted(true);
        // setRollbackOngoing(true);
      },
    }
  );

  //Call the Rollback Process API to trigger the upgrade statemachine
  const [userRollbackProcess] = useLazyQuery(UserRollbackProcessDocument, {});

  // Get CFN Template for rollback stack and open it in new tab window
  const [getRoleTemplateURL] = useLazyQuery(GetUrlDocument, {});

  const buttonclick = async (values: any) => {
    setDisable(true);
    setRollbackStatus(true);
    //get the rollback cfn template file path
    const results = await Promise.all([
      getRoleTemplateURL({
        variables: {
          template:
            "user-deploy-process-templates/SixPillarsRollbackRoleStack.template.json", // value for 'template'
        },
        fetchPolicy: "no-cache",
      }),

      //initiate the rollback statemachine
      userRollbackProcess({
        variables: {
          organisation: organisation, // value for 'organisation'
          accessnickname: accessnickname, // value for 'accessnickname'
          arn: arn, // value for 'arn'
          externalid: externalId, // value for 'externalid'
          region: defaultregion, // value for 'region'
          keepSecHub: values?.question1, // value for keepSecHub
          keepConfig: "Yes", // value for keepConfig
        },
        context: {
          apiName: "user_deploy_process",
        },
        fetchPolicy: "no-cache",
      }),
    ]);
    let launchurl = `https://console.aws.amazon.com/cloudformation/home?region=${defaultregion}#/stacks/create/review?stackName=six-pillars-rollback&templateURL=${encodeURIComponent(
      results[0]?.data.getTemplateS3URL
    )}&param_ExternalId=${externalId}`;
    setTemplateUrl(launchurl);
    setDisplayQuestions(true);
    setTimeout(
      () =>
        automateProgressStatus({
          variables: {
            accountid: accountid,
            organisation: organisation,
          },
          context: {
            apiName: "user_deploy_process",
          },
        }),
      15
    );
  };

  useEffect(() => {
    if (accountStates[arn]?.rollbackOngoing === true) {
      getRoleTemplateURL({
        variables: {
          template:
            "user-deploy-process-templates/SixPillarsRollbackRoleStack.template.json", // value for 'template'
        },
        fetchPolicy: "no-cache",
      });
    }
  }, [accountStates[arn]?.rollbackOngoing]);

  return (
    <Stack direction="row">
      {templateUrl === "" && accountStates[arn]?.rollbackOngoing === false ? (
        <Button
          onClick={handleOpen}
          id="automate-rollback-btn"
          type="submit"
          variant="contained"
          sx={{
            width: 90,
            height: 40,
            bgcolor: "secondary.main",
            "&:hover": {
              bgcolor: "secondary.main",
              color: "secondary.contrastText",
            },
          }}
        >
          Rollback
        </Button>
      ) : (
        <Button
          href={templateUrl}
          id="automate-template-launch-rollback-btn"
          type="submit"
          target={"_blank"}
          rel={"noopener"}
          variant="contained"
          sx={{
            display: "flex",
            alignItems: "center",
            width: 200,
            height: 40,
            bgcolor: "secondary.main",
            "&:hover": {
              bgcolor: "secondary.main",
              color: "secondary.contrastText",
            },
          }}
        >
          Open Template <OpenInNewIcon />
        </Button>
      )}
      <RollbackAccountModal
        open={open}
        arn={arn}
        disable={disable}
        handleClose={handleClose}
        handleProceed={buttonclick}
        rollbackStatus={rollbackStatus}
        displayQuestions={displayQuestions}
        formikRef={formikRef} // Pass formikRef to RollbackAccountModal
        templateUrl={templateUrl}
      ></RollbackAccountModal>
    </Stack>
  );
}
