import { useState } from "react";
import Stack from "@mui/material/Stack";
import { useDeleteAccountMutation } from "../../generated";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import useAccount from "../../hooks/useAccount";
import useSnackBars from "../../hooks/useSnackbar";
import { FormControl } from "@mui/material";


interface AccountState {
  automateProgress: boolean;
  progressStatus: string;
  createdDate: string;
  rollbackOngoing: boolean;
  accountDeleted: boolean;
}
interface DeleteAccountProps {
  arn: string;
  updateAccountState:(arn: string, newState: Partial<AccountState>) => void;
  // setTableUpdateData: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DeleteAccount({
  arn,
  updateAccountState,
  // setTableUpdateData,
}: DeleteAccountProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [disable, setDisable] = useState(false);
  const { refreshAccounts } = useAccount();
  const { setAlerts } = useSnackBars();

  //Delete account api
  const [deleteAccountMutation] = useDeleteAccountMutation({
    variables: {
      arn: arn, // value for 'arn'
    },
    context: {
      apiName: "user_deploy_process",
    },
    onCompleted: () => {
      // setTableUpdateData(true);
      handleClose();
    },
  });

  function Buttonclick() {
    setDisable(true);
    //initiate delete account api
    deleteAccountMutation({
      variables: {
        arn: arn, // value for 'arn'
      },
      context: {
        apiName: "user_deploy_process",
      },
    })
      .then(({ data }: any) => {
        refreshAccounts(data);
        setAlerts([
          {
            severity: "success",
            msg: "Account successfully deleted",
          },
        ]);
      })
      .catch((e) => {
        setAlerts([
          {
            severity: "error",
            msg: "Account deletion failed - " + e,
          },
        ]);
      });
  }

  return (
    <Stack direction="row">
      <FormControl fullWidth>
        <Button
          onClick={handleOpen}
          type="submit"
          variant="contained"
          sx={{
            bgcolor: "secondary.main",
            "&:hover": {
              bgcolor: "secondary.main",
              color: "secondary.contrastText",
            },
          }}
        >
          Delete Account
        </Button>
      </FormControl>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs md={10}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Disconnect Account from AUTOMATE+
              </Typography>
            </Grid>
            <Grid item xs md={2}>
              <Button onClick={() => handleClose()}>
                <CloseIcon />
              </Button>
            </Grid>
            <Grid item xs md={12}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure?
              </Typography>
              <Typography id="modal-modal-description">
                Disconnecting your AWS Account from AUTOMATE+ will disable all
                remediation. To undo account disconnection, you will need to
                re-deploy AUTOMATE+.
              </Typography>
            </Grid>
            <Grid item xs md={4} sx={{ mt: 2 }}></Grid>
            <Grid item xs md={8} sx={{ mt: 2 }}>
              <Stack sx={{ pt: 4 }} direction="row" spacing={2}>
                <Button
                  disabled={disable}
                  onClick={() => handleClose()}
                  type="submit"
                  variant="contained"
                  sx={{
                    width: 157,
                    height: 40,
                    bgcolor: "secondary.main",
                    "&:hover": {
                      bgcolor: "secondary.main",
                      color: "secondary.contrastText",
                    },
                  }}
                >
                  No
                </Button>
                <Button
                  disabled={disable}
                  onClick={() => Buttonclick()}
                  type="submit"
                  variant="outlined"
                  sx={{
                    width: 157,
                    height: 40,
                    "&:hover": {
                      color: "primary.main",
                    },
                  }}
                >
                  Yes
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Stack>
  );
}
